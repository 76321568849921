import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-bold">
          Admin Panel
        </div>
        <div className="flex space-x-4">
          <Link to="/admin" className="text-white hover:text-gray-400">
            Home
          </Link>
          <Link to="/admin/add-food" className="text-white hover:text-gray-400">
            Add Food
          </Link>
          <Link to="/admin/categories" className="text-white hover:text-gray-400">
            Add Category
          </Link>
          <Link to="/admin/food-list" className="text-white hover:text-gray-400">
            Food List
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
