import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductForm from '../components/ProductForm';
import axios from 'axios';

const EditPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`https://app.gyrosnj.com/foods/${id}`);
                setProduct(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
    }, [id]);

    const onSave = async (productData) => {
        try {
            const categories = await axios.get("https://app.gyrosnj.com/categories");
            const categoryId = categories.data.find(category => category.name === productData.category)._id;
            productData.category = categoryId;
            await axios.put(`https://app.gyrosnj.com/foods/edit/${id}`, productData);
            console.log('Product updated successfully');
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <div>
            {product ? (
                <ProductForm product={product} onSave={onSave} />
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default EditPage;
