import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');


  // Kategorileri getiren useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://app.gyrosnj.com/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);



  // Yeni kategori eklemek için handleSubmit fonksiyonu
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://app.gyrosnj.com/categories', {
        name: newCategoryName
      });
      setCategories([...categories, response.data]); // Yeni kategori listeye ekleniyor
      setNewCategoryName(''); // Input alanı temizleniyor
      console.log('Category added successfully:', response.data);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  // Kategori silme işlemi
  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this category?');
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`https://app.gyrosnj.com/categories/delete/${categoryId}`);
      setCategories(categories.filter((category) => category._id !== categoryId));
      console.log('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };


  return (
    <div className="m-4">
      <h2 className="text-2xl font-bold mb-4">Category List</h2>

      {/* Kategori ekleme formu */}
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="flex">
          <input
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Enter new category name"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
          />
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Category
          </button>
        </div>
      </form>

      {/* Kategori listesi */}
      <ul className="divide-y divide-gray-200">
        {categories.map((category) => (
          <li key={category._id} className="py-2 flex justify-between items-center">
            <span>{category.name}</span>
            <button
              onClick={() => handleDeleteCategory(category._id)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
            >
              × Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryPage;
