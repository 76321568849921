import React from 'react';
import { Link } from 'react-router-dom';

const DashboardCard = ({ title, link }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 text-center">
      <Link to={link}>
        <h2 className="text-xl font-bold mb-2">{title}</h2>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-md mt-4">
          Go to {title}
        </button>
      </Link>
    </div>
  );
};

export default DashboardCard;
