import React from 'react';
import DashboardCard from './DashboardCard';

const AdminPanel = () => {


  return (
    <>
      <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <DashboardCard title="Add Food" link="/admin/add-food" />
        <DashboardCard title="Add Category" link="/admin/add-category" />
        <DashboardCard title="View Food List" link="/admin/food-list" />
        <DashboardCard title="View Orders" link="/admin/orders" />
      </div>
    </div>
    </>

  );
};

export default AdminPanel;
