import React, { useEffect, useState } from 'react';
import ProductList from '../components/ProductList';
import axios from 'axios';

function FoodList() {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    // Fetch products and categories
    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsResponse = await axios.get('https://app.gyrosnj.com/foods');
                const categoriesResponse = await axios.get('https://app.gyrosnj.com/categories');
                setProducts(productsResponse.data);
                setCategories(categoriesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // DELETE
    const handleDeleteProduct = async (productId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this product?');
        if (!confirmDelete) {
            return;
        }

        try {
            await axios.delete(`https://app.gyrosnj.com/foods/delete/${productId}`);
            setProducts(products.filter((product) => product._id !== productId));
            console.log('Product deleted successfully');
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };


    return (
        <div>
            <ProductList products={products} categories={categories} onDelete={handleDeleteProduct}/>
        </div>
    );
}

export default FoodList;
