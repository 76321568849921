import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPanel from './components/AdminPanel';
import FoodList from './pages/FoodList';
import Navbar from './components/Navbar';
import AddFoodPage from './pages/AddFoodPage';
import EditPage from './pages/EditPage';
import OrderPage from './pages/OrderPage';
import ManageCategories from './pages/CategoryPage';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<AdminPanel />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path='/admin/food-list' element={<FoodList />} />
          <Route path='/admin/add-food' element={<AddFoodPage />} />
          <Route path='/admin/edit/:id' element={<EditPage />} />
          <Route path='/admin/orders' element={<OrderPage />} />
          <Route path='/admin/categories' element={<ManageCategories />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
