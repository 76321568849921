import React from 'react'
import ProductForm from '../components/ProductForm'
import axios from 'axios';

function AddFoodPage() {

  const onSave = async (productData) => {
    try {
      const categories = await axios.get("https://app.gyrosnj.com/categories");
      const categoryId = categories.data.find(category => category.name === productData.category)._id;
      productData.category = categoryId;
      await axios.post('https://app.gyrosnj.com/foods', productData);
      console.log('Product saved successfully');
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };
  


  return (
    <div>
      <ProductForm onSave={onSave} />
    </div>
  )
}

export default AddFoodPage